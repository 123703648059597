import { Avatar, Card, Divider } from '@mui/material'
import React from 'react'
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GoogleIcon from '@mui/icons-material/Google';

const changePassword = ()=>{
    //change password here
}

function Profile(props) {

    const theme = useTheme();
    return (
        <Card elevation={3} sx={{ maxWidth: 'false' }}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div" sx={{display:'flex',alignItems:'center'}}>
                John Doe
                </Typography>
                <Card sx={{ display: 'flex',marginBottom:1, flexDirection:'row', gap:2, justifyContent:'space-evenly'}} elevation={0}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h5">
                            Profile
                        </Typography>
                        <Avatar alt="Me" src="https://unsplash.com/photos/woman-standing-near-blue-steel-gate-O3ymvT7Wf9U" />
                        </CardContent>
                        <Typography component="div" variant="h5">
                            Wallet : 0
                        </Typography>
                        
                    </Box>
                    <Divider variant='middle' orientation='vertical'/>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap:3 }}>
                        <Box>
                        <Typography component="div" variant="h6">
                            Username
                        </Typography>
                        <Typography component="div" variant="subtitle">
                            {'props.username'}
                        </Typography>
                        </Box>
                        <Box>
                        <Divider variant='middle' orientation='horizontal'/>
                        <Typography component="div" variant="h6">
                            Email
                        </Typography>
                        <Typography component="div" variant="subtitle">
                            {'props.email'}
                        </Typography>
                        </Box>
                        <Button variant='contained' onClick={changePassword}>Change Password</Button>
                    </Box>
                </Card>
                <Divider variant='middle' orientation='horizontal'/>
                <Card elevation={0}  sx={{ display: 'flex', marginTop:1, flexDirection:'column',p:3, gap:5, marginBottom:5, justifyContent:'left' }}>
                    <Typography variant="h6" color="primary">
                    About me
                    </Typography>
                    
                    <Typography variant="subtitle" color="primary">
                    Am very hard working
                    </Typography>

                </Card>

            </CardContent>
            <CardActions>
          <FacebookIcon color='primary'/>
          <WhatsAppIcon sx={{color:'#128C7E'}}/>
          <InstagramIcon sx={{color:'#F56040'}}/>
          <GoogleIcon/>
          <LinkedInIcon sx={{color:'#0072b1'}}/>
        </CardActions>
      </Card>
    )
}

export default Profile
