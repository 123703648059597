import { Button, Card, Stack, TextareaAutosize, TextField, Typography } from '@mui/material'
import React from 'react'

function CreateTask(props) {
    const data = props.getData
    return (
        <Card elevation={3}>
            <Stack direction={'column'} spacing={2}>

                
                <Button variant='outlined'> Create</Button>
                
                

            </Stack>
            
        </Card>
    )
}

export default CreateTask
