import { Button, Card, Typography } from '@mui/material';
import React from 'react';
import Create from './Create';
import Transfer from './Transfer';

function Warehousing(props) {
const f = props.method
const loadCreate = ()=>{
 f(<Create/>)
}
const loadTransfer = ()=>{
    f(<Transfer/>)
}

    return (
        <div>
                <Card elevation={0}  sx={{ display: 'flex', marginTop:1, flexDirection:'column',p:3, gap:5, marginBottom:5, justifyContent:'left' }}>
                <Typography variant="h6" color="primary">
                Warehousing
                </Typography>
                <Button variant='contained' onClick={loadCreate}>Create</Button>
                <Button variant='contained' onClick={loadTransfer}>Transfer</Button>
                </Card>
            
        </div>
    )
}

export default Warehousing
