import { Button, Stack, TextField } from '@mui/material'
import React from 'react'

function Credit() {
    return (
        <Stack gap={2}>

        <TextField size='small' label='Ref number' sx={{textAlign:'center'}}/>
        <Button variant='outlined' size='small'>Search</Button>

        </Stack>
    )
}

export default Credit