import { Avatar, Card, CardActions, CardContent, CardHeader, IconButton, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import React from 'react'
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import ReplyIcon from '@mui/icons-material/Reply';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';

function Message(props) {
    // const mes = props.message;
    const mes = {sender:'Musa Makhmud',title: 'Greeting',date:'12/4/2024',
      body:'Hello hope you are doing well I wanted to ask of you can ...'
     }
    return (
        <div><Typography variant='h6'>Inbox</Typography>
            <Card sx={{ maxWidth: 345 }} elevation={5}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {mes.sender[0]}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={<Typography variant='h6'>{mes.sender}</Typography>}
        subheader={<Typography variant='p'>{mes.date}</Typography>}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
            {mes.body}
        </Typography>
      </CardContent>
      <CardActions  sx={{display:'flex', justifyContent:'center'}}>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="reply">
          <ReplyIcon />
        </IconButton>
        <IconButton aria-label="like">
          <ThumbUpOffAltOutlinedIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      </CardActions>     
    </Card>
            
            
        </div>
    )
}

export default Message
