import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function Login() {
    const [username, setusername] = useState('')
    const [pass, setpass] = useState('')
  return (
    <div className="loginCard">
      <Card elevation={5}>
        <Typography mt={2} variant="h5" align="center" color="primary">
          KALSoft
        </Typography>
        <CardHeader
          title={
            <Typography variant="h6" align="center" color="primary">
              Login
            </Typography>
          }
          
        ></CardHeader>
        <CardContent><Typography variant="subtitle" align="center" color="error">
              All fields with * are necessary
            </Typography>
          <form>
            <div className="username">
              <TextField
                size="small"
                id="username"
                label="Email"
                onChange={(e)=>setusername(e.target.value)}
                fullWidth
                required
              />
            </div>

            <div className="password">
              <TextField
                id="password"
                label="Password"
                type="password"
                fullWidth
                value={pass}
                onChange={(e)=>setpass(e.target.value)}
                size="small"
                required
              />
            </div>
            <div className="rememberMe">
              <FormControlLabel
                value={"remember"}
                control={<Checkbox />}
                label="Remember me"
              ></FormControlLabel>
            </div>
            <div className="loginButton">
              <NavLink to={"/dashboard"} replace={false}>
                <Button onClick={()=>console.log(username)} className="login" variant="contained">
                  Login
                </Button>
              </NavLink>
            </div>
          </form>
          <div className="forgotPassword">
            <Button size="small" href="/signup">
              Create Account
            </Button>
            <Divider orientation="vertical" flexItem />
            <Button size="small">Forgot password?</Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default Login;
