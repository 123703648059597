import { Chip, Divider, Stack } from '@mui/material';
import React, { useState } from 'react'

// categories
const categories = ["Create","Inbox", "Sent"];

function Mail() {
  // Categories for products
  const [bcolor, setbColor] = useState([
    "info",
    "success",
    "success",
    
  ]);
  let resetColor = [
    "info",
    "info",
    "info",
  ];
  const handleClick = (n) => {
    let newArray = [...resetColor];
    newArray[n] = "success";
    setbColor(newArray);
  };

  return (
    <div>
      <Stack direction={"column"} mb={2}>
        <Stack direction={"row"} mb={2} justifyContent={'right'}>
          {categories.map((cat, index) => {
            return (
              <Chip sx={{marginRight:2}}
                label={cat}
                color={bcolor[index]}
                onClick={() => handleClick(index)}
              />
            );
          })}
        </Stack>
        <Divider variant="" orientation="horizontal" />
      </Stack>
      </div>
  )
}

export default Mail
