import { Box, Button, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridToolbar, GridToolbarQuickFilter, useGridApiRef } from "@mui/x-data-grid";
import { id } from "date-fns/locale";
import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import React, { useState } from "react";



const datarows = [
    {
      id: 0,
      name: "Loveren",
      tel: 2154521,
      orderid: 21155,
      age: 23,
      gender: "M",
      email: "lv@example.com",
      amount:3520,
    },
    {
      id: 1,
      name: "Melvin",
      tel: 2332234,
      orderid: 45356,
      age: 45,
      gender: "F",
      email: "me@example.com",
      amount:450,
    },
    {
      id: 2,
      name: "Oprah",
      tel: 2343423,
      orderid: 55645,
      age: 56,
      gender: "F",
      email: "op@example.com",
      amount:12025,
    },
    {
      id: 3,
      name: "Kane",
      tel: 3453356,
      orderid: 76894,
      age: 32,
      gender: "M",
      email: "kn@example.com",
      amount:56540,
    },
    {
      id: 4,
      name: "Kali",
      tel: 5647453,
      orderid: 32453,
      age: 43,
      gender: "M",
      email: "kl@example.com",
      amount:1200,
    },
    {
      id: 5,
      name: "Helum",
      tel: 7698656,
      orderid: 76656,
      age: 21,
      gender: "M",
      email: "he@example.com",
      amount:2540,
    },
    {
      id: 6,
      name: "Neone",
      tel: 9766786,
      orderid: 45453,
      age: 43,
      gender: "M",
      email: "ne@example.com",
      amount:3520,
    },
  ];
let number =datarows.length;

function Runpayroll() {
  const [loading, setloading] = useState(false);
  const [rows, setRows] = useState(datarows);
  const [rowModesModel, setRowModesModel] = useState({})

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    number +=1;
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  const cols = [
    { field: "id", headerName: "ID", width: 10 , editable:true},
    { field: "name", headerName: "Name" , editable:true},
    { field: "tel", headerName: "Telephone" , editable:true},
    { field: "orderid", headerName: "Order ID", editable:true, width:70 },
    { field: "age", headerName: "Age", editable:true, width:10 },
    {
      field: "gender",
      headerName: "Gender",
      editable: true,
      width:50,
      type: "singleSelect",
      valueOptions: ["M", "F"],
    },
    { field: "email", headerName: "Email", editable: true, width: 130 },
    { field: "amount", headerName: "Amount", editable:true},
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;  
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }
  
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
  ];
  
  return (
    <Box height={"80vh"} mt={2}>
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'}>
      <Typography variant="h6" color={'secondary'} >Orders</Typography>
      </Stack>
      <DataGrid
        aria-label="Employees"
        columns={cols}
        rows={rows}
        getRowId={(row) => row.id}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        apiRef={useGridApiRef()}
        pageSizeOptions={[5, 10, 15, 20, 100]}
        // processRowUpdate={(newRow, oldRow) => updateRow(newRow)}
        onProcessRowUpdateError={(error) => {
          console.log(error);
        }}
        columnVisibilityModel={{ age: true, gender: true }}
        slots={{ toolbar: myToolbar }}
        loading={loading}
        slotProps={{ toolbar: { setRows, setRowModesModel } }}
      />
    </Box>
  );
}

export default Runpayroll;

function myToolbar(props) {

    const { setRows, setRowModesModel } = props;

    const handleClick = () => {

      const id = number;
      setRows((oldRows) => [...oldRows, { id, name: '', telephone:'', idno:'',age:'',
        gener:'F', email:'',role:''
      }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      }));
    };


  return (
    <GridToolbarContainer>
      <Stack direction={"row"} my={3} spacing={4}>
        <GridToolbar />
        <GridToolbarQuickFilter sx={{marginLeft:'auto'}}/>
        <Button color="success" size="small" variant="contained" sx={{marginLeft:'auto'}} onClick={handleClick}>
          <Tooltip title='new'><AddIcon /></Tooltip>
        </Button>
      </Stack>
      <Divider/>
    </GridToolbarContainer>
  );
}
