import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";




const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

function Damaged() {
  const [product, setproduct] = useState([]);
  const [verifyColor, setverifyColor] = useState("primary");
  const [disabled, setdisabled] = useState(true);
  const [text, settext] = useState("verify");

  const addProduct = () => {
    let prod = [...product];
    if (prod.length === 0) {
      prod.push(0);
      setproduct(prod);
    } else {
      let n = prod.pop();
      prod.push(n);
      prod.push(n + 1);
      setproduct(prod);
    }
  };

  const removeInput = (index) => {
    let prod = [...product];
    prod.splice(index, 1);
    console.log(prod);
    setproduct(prod);
  };

  const verifyOrder = () => {
    setverifyColor("success");
    setdisabled(false);
    settext("verified");
  };
  return (
    <Stack gap={2}>
      <Typography>Damaged Goods</Typography>
      <TextField
        size="small"
        label="Order number"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={verifyOrder}>
                <CheckCircleOutlinedIcon color={verifyColor} />
                {text}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ textAlign: "center" }}
      />
      {product.map((p, index) => {
        return (
          <TextField
            id={index}
            size="small"
            label="Product"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => removeInput(index)}>
                    <CancelIcon sx={{ color: "red" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        );
      })}
      <Button
        disabled={disabled}
        sx={{ width: "50vw", alignSelf: "center" }}
        variant="outlined"
        size="small"
        onClick={addProduct}
      >
        Add
      </Button>
      <Stack display={"flex"} flexDirection={"row"}>
        <Button
          component="label"
          disabled={disabled}
          role={undefined}
          size="small"
          variant="outlined"
          tabIndex={-1}
          sx={{ width: "25vw" }}
          startIcon={<CloudUploadIcon />}
        >
          Upload file
          <VisuallyHiddenInput type="file" />
        </Button>
        
        <Button
          disabled={disabled}
          sx={{ marginLeft: "auto" }}
          variant="outlined"
          size="small"
        >
          Confirm
        </Button>
      </Stack>
    </Stack>
  );
}

export default Damaged;
