import { Chip, Divider, Fab, Stack, Tooltip } from '@mui/material';
import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Activity from './Activity'
import Calendar from './Components/Calendar';




function Activities() {
    const [component, setComponent] = useState(null)


  

  return (
    <Stack direction={"row"} mb={2} width={'75%'}>
      {/* remove/ add/ edit user perms */}
      <Stack direction={"column"} mb={2} width={'60vw'}>
       <Activity/>
      </Stack>
      <Stack direction={"column"} mb={2} width={'25vw'}>
       <Calendar/>
      </Stack>
    </Stack>
  );
}

export default Activities
