import React from 'react'

function CustomersReports() {
    return (
        <div>
            Reports
        </div>
    )
}

export default CustomersReports
