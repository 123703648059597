import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

function Receipts() {
  return (
    <Box>
      <Stack gap={2}>
        <Typography variant="h6">Cash Receipt</Typography>
        <Typography variant="subtitle">Company name - Outlet</Typography>
        <Typography variant="subtitle">Contacts</Typography>
        <Typography variant="subtitle">Ref id</Typography>
        <Typography variant="subtitle">TAx Pin</Typography>
        <Divider />
        <Stack display={"flex"} flexDirection={"row"} mt={2}>
          <Typography color="secondary">
            {" "}
            Date : {new Date().getDate()}/{new Date().getMonth()}/
            {new Date().getFullYear()}
          </Typography>
          <Typography ml={2} color="secondary">
            Time : {("0" + new Date().getHours()).slice(-2)}:
            {("0" + new Date().getMinutes()).slice(-2)}:
            {("0" + new Date().getSeconds()).slice(-2)}
          </Typography>
          <Typography marginLeft={"auto"} color="secondary">
            Serial Number : 01212545
          </Typography>
        </Stack>
        <Divider />
        <Stack display={"flex"} flexDirection={"row"} mt={2} gap={2}>
          <TextField label="Amount" id="amount" size="small" />
          <TextField label="Mode of Pay" id="mode" size="small" />
        </Stack>
        <Stack display={"flex"} flexDirection={"row"} mt={2} gap={2}>
          <TextField label="Received from... " id="from" size="small" />
          <TextField label="For ..." id="for" size="small" />
          <TextField label="Order Number" id="order" size="small" />
        </Stack>
        <Stack display={"flex"} flexDirection={"row"} gap={2} justifyContent={'center'}>
          <Button size="small" color="success" variant="outlined">
            Save
          </Button>
          <Button size="small" color="success" variant="outlined">
            Save and Print
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Receipts;
