import { AppBar, Avatar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material'
import React from 'react'
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import Profile from '../Misc/Profile';
import Cart from '../Sales/Cart';
import MainHRM from '../HRM/MainHRM';
import Message from '../Vendor/Message';
import Wall from '../Misc/Wall'
import Todo from '../Misc/Todo'
import SettingsIcon from '@mui/icons-material/Settings';
import MessageIcon from '@mui/icons-material/Message';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Setting from '../Settings/Setting'
import Notify from './Notify'


  
  const pages = [{icon:<MessageIcon/>,tooltip:'messages'},
                 {icon:<PostAddIcon/>,tooltip:'wall'},
                 {icon:<ListAltIcon/>,tooltip:'todo'},
                 {icon:<CircleNotificationsIcon/>,tooltip:'notifications'},
                 {icon:<SettingsIcon/>,tooltip:'settings'}];
  const settings = [{title:'Profile', icon:<AccountCircleIcon/>},{title:'Cart', icon:<ShoppingCartOutlinedIcon/>},{title:'Dashboard', icon:<SpaceDashboardOutlinedIcon/>},
    {title:'Logout', icon:<LogoutOutlinedIcon/>}];
  
  function ResponsiveAppBar(props) {
    const navigate = useNavigate()

    const sx = props.sx;
    const setComponent = props.method;

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
      
    };
  
    const handleCloseNavMenu = (page) => {
      setAnchorElNav(null);
      switch(page){
        case pages[0]: setComponent(<Message/>)
          break;
        case pages[1]: setComponent(<Wall/>)
          break; 
        case pages[2]: setComponent(<Todo/>)
          break;
        case pages[3]: setComponent(<Notify/>)
          break;
        case pages[4]: setComponent(<Setting/>)
          break;
        default:
      }
    };
  
    const handleCloseUserMenu = (set) => {
      if(set){
        if(set===settings[3]){
            setAnchorElUser(null);
            navigate('/home');
          }
        else if(set===settings[0]){
            setAnchorElUser(null);
            setComponent(<Profile/>);
            }
        else if(set===settings[1]){
            setAnchorElUser(null);
            setComponent(<Cart/>);
            }
        else if(set===settings[2]){
            setAnchorElUser(null);
            setComponent(<MainHRM/>);
            }
      }
      
      setAnchorElUser(null);
    };
  
    
    return (
        <div>
            <AppBar position="fixed" sx={sx}
        >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            KALSoft
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page,index) => (
                <MenuItem key={index} onClick={()=>handleCloseNavMenu(page)} >
                  <Tooltip title={page.tooltip} placement='right-start'>{page.icon}</Tooltip>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            KALSoft
          </Typography>
         
              {/* page title */}
         <Typography className='appbarTitle' variant='h6'>{props.title}</Typography> 

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page,index) => (
              <Button
                key={index}
                onClick={()=>handleCloseNavMenu(page)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              ><Tooltip title={page.tooltip}>{page.icon}</Tooltip>
                
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.title} onClick={()=>handleCloseUserMenu(setting)}>
                  {setting.icon}
                  <Typography textAlign="center" marginLeft={1} >{setting.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
        </div>
    )
}

export default ResponsiveAppBar
