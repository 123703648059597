import { Button, Divider, Stack, TextField, Typography } from '@mui/material'
import React from 'react'

function Packing() {
    return (
        
        <Stack gap={2}>
        <Typography>Packing List</Typography>
        <TextField size='small' label='Order number' sx={{textAlign:'center'}}/>
        <Button variant='outlined' size='small'>Search</Button>

        </Stack>
    )
}

export default Packing
