import React from 'react'
import '../Components/bg-image.jpg'
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
import "../Components/bg-image.jpg";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Chip, Fab, Stack, Tooltip } from '@mui/material';
import Tasks from './Components/Tasks'
import Assign from './Components/Assign'



const categories = ["View Task", "Employee Assignment", "Scheduling"];

// summary, analysis in analytics
function Work() {
const [component, setcomponent] = useState(<Tasks/>)
    const [bcolor, setbColor] = useState([
        "secondary",
        "primary",
        "primary",
       
      ]);
      let resetColor = [
        "primary",
        "primary",
        "primary",
        
      ];
      const handleClick = (n) => {
        let newArray = [...resetColor];
        newArray[n] = "secondary";
        setbColor(newArray);
        switch(categories[n]){
          case 'View Task': setcomponent(<Tasks/>); break;
          case 'Employee Assignment': setcomponent(<Assign/>); break;
          case 'Scheduling': setcomponent("Schedule task"); break;
          default:
        }
      };
  return (
    <div>
      {/* remove/ add/ edit user perms */}
      <Stack direction={"column"} mb={2}>
        <Stack direction={"row"}>
          {categories.map((cat, index) => {
            return (
              <Chip
                sx={{ marginRight: 2 }}
                label={cat}
                color={bcolor[index]}
                onClick={() => handleClick(index)}
              />
            );
          })}
          
        </Stack>
        {component}
      </Stack>
    </div>
  );
                  
    
}

export default Work;
