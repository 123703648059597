import { Box, CardMedia, Divider, Grid, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import GroupsIcon from '@mui/icons-material/Groups';
import WebhookIcon from '@mui/icons-material/Webhook';
import PhishingIcon from '@mui/icons-material/Phishing';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import HubTwoToneIcon from '@mui/icons-material/HubTwoTone';
import imag from '../Components/bg-image.jpg'

function Dashboard() {


   const items =[{icon:<GroupsIcon/> ,title:'Contacts'},{icon:<PhishingIcon/> ,title:'Create Lead'},{icon: <WebhookIcon/>,title:'Leads'},{icon:<PersonAddAltIcon/> ,title:'Create Contacts'},
    {icon:<HandshakeTwoToneIcon/> ,title:'Deals'}, {icon:<HubTwoToneIcon/> ,title:'Activities'}
   ] 
  return (
    <div>
      <Stack display={"flex"} flexDirection={"row"} gap={2} paddingTop={1}>
        <Typography mt={1}> Dashboard</Typography>
        <Tooltip title='customize dashboard'><AppRegistrationIcon sx={{marginLeft:'auto'}}/></Tooltip>
      </Stack>
      <Divider/>
      <Stack display={"flex"} flexDirection={"row"} gap={2} paddingTop={1}>
        <Grid container spacing={1}  gridRow={'auto'} gridColumn={'auto'} rowGap={1} columnGap={1}>
                {items.map((item, index)=>{
                    return (
                        <Grid item key={index} xs={3}>
                            <DashboardCard getData = {item}>

                            </DashboardCard>
                        </Grid>
                    )
                })}
        </Grid>

      </Stack>
    </div>
  );
}

export default Dashboard;




function DashboardCard(props) {
    const data = props.getData
    return (
        <div onClick={()=>{

        }}>
           
                <CardMedia sx={{height:200, width:200}}
                image={imag}
                />

                
           { data.title}
        </div>
    )
}


