import {
    Box,
    Button,
    Divider,
    IconButton,
    Stack,
    Tooltip,
  } from "@mui/material";
  import AddIcon from "@mui/icons-material/Add";
  import {
    DataGrid,
    GridToolbar,
    GridToolbarQuickFilter,
    useGridApiRef,
  } from "@mui/x-data-grid";
  import {
    GridRowModes,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
  } from "@mui/x-data-grid";
  import EditIcon from "@mui/icons-material/Edit";
  import DeleteIcon from "@mui/icons-material/DeleteOutlined";
  import SaveIcon from "@mui/icons-material/Save";
  import CancelIcon from "@mui/icons-material/Close";
  import React, { useState } from "react";
  
  const datarows = [
    {
      id: 1,
      name: "Ewaso Trading Maniacs",
      contact: "07545845",
      email: "ewaso_tm@example.com",
      taxno:'P10121112FH',
      expenses: 4900,
      bills: 54800,
    },
  ];
  let number = datarows.length;
  
  function Expenses() {
    const [loading, setloading] = useState(false);
    const [rows, setRows] = useState(datarows);
    const [rowModesModel, setRowModesModel] = useState({});
  
    const handleRowEditStop = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };
  
    const handleEditClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
  
    const handleSaveClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };
  
    const handleDeleteClick = (id) => () => {
      setRows(rows.filter((row) => row.id !== id));
    };
  
    const handleCancelClick = (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
  
      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    };
  
    const processRowUpdate = (newRow) => {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      number += 1;
      return updatedRow;
    };
  
    const handleRowModesModelChange = (newRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };
  
    const cols = [
      { field: "id", headerName: "ID", width: 10, editable: true },
      { field: "name", headerName: "Name", editable: true, width: 130 },
      { field: "contact", headerName: "Contact", editable: true, width: 80 },
      { field: "email", headerName: "Email", editable: true, width: 130 },
      { field: "taxno", headerName: "Tax Pin", editable: true, width: 120},
      { field: "expenses", headerName: "Expenses", editable: true, width: 80 },
      {
        field: "bills",
        headerName: "Bills",
        editable: true,
        width: 80,
      },
      
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 100,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }
  
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  
    return (
      <Box height={"80vh"} mt={2}>
        <DataGrid
          aria-label="Employees"
          columns={cols}
          rows={rows}
          getRowId={(row) => row.id}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          apiRef={useGridApiRef()}
          pageSizeOptions={[5, 10, 15, 20, 100]}
          onProcessRowUpdateError={(error) => {
            console.log(error);
          }}
          columnVisibilityModel={{ age: true, gender: true }}
          slots={{ toolbar: myToolbar }}
          loading={loading}
          slotProps={{ toolbar: { setRows, setRowModesModel } }}
        />
      </Box>
    );
  }
  
  function myToolbar(props) {
    const { setRows, setRowModesModel } = props;
  
    const handleClick = () => {
      const id = number + 1;
      setRows((oldRows) => [
        ...oldRows,
        {
          id,
          name: "",
          contact: "",
          email: "",
          taxno: "",
          expenses: "",
          bills: "",
          
        },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
      }));
    };
  
    return (
      <GridToolbarContainer>
        <Stack direction={"row"} my={3} spacing={4}>
          <GridToolbar />
          <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
          <Button
            color="success"
            size="small"
            variant="contained"
            sx={{ marginLeft: "auto" }}
            onClick={handleClick}
          >
            <Tooltip title="new">
              <AddIcon />
            </Tooltip>
          </Button>
        </Stack>
        <Divider />
      </GridToolbarContainer>
    );
  }
  
  export default Expenses;
  
  
 
  