import React from 'react'

function Lpo() {
    return (
        <div>
            Local Purchase Order
        </div>
    )
}

export default Lpo
