import { Typography } from '@mui/material'
import React from 'react'

function Notify() {
    return (
        <div>
            <Typography variant='h3'>Notifications</Typography>
        </div>
    )
}

export default Notify
