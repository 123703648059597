import React from "react";
import Box from "@mui/material/Box";
import { Button, Divider, TextField, Typography } from "@mui/material";

function Create() {
  return (
    <div>
      <Box mt={2}>
        <Typography variant="subtitle" sx={{ marginLeft: 5 }}>
          General
        </Typography>
      </Box>
      <form noValidate>
        <Box display={"column"} rowGap={3}>
          <TextField id="name" label="Name" variant="standard" fullWidth />
          <TextField
            id="location"
            label="Location"
            variant="standard"
            fullWidth
          />
          <TextField
            id="contact"
            label="Contact"
            variant="standard"
            fullWidth
          />
        </Box>
        <Box mt={2}>
          <Typography  variant="subtitle" sx={{ marginLeft: 5 }}>
            Administration
          </Typography>
        </Box>

        <Box display={"column"} rowGap={3}>
          <TextField
            id="head"
            label="Senior Warehouse officer"
            variant="standard"
            fullWidth
          />
          <TextField
            id="Assistant"
            label="Assistant"
            variant="standard"
            fullWidth
          />
          <TextField
            id="supervisor"
            label="Supervisor"
            variant="standard"
            fullWidth
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", margin: 4 }}>
          <Button variant="outlined" sx={{ width: 0.5 }}>
            Create
          </Button>
        </Box>
      </form>
    </div>
  );
}

export default Create;
