import React, { useState } from 'react'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Tooltip } from '@mui/material';

function Item(props) {
    const item = props.item
    
    const [number, setNumber] = useState(0)

    const handleNumber = (e)=>{
        setNumber(e.target.vale);
    }
    const addToCart = ()=>{
        //add to cart here
    }
    return (
        <Card sx={{ maxWidth: 345 } } elevation={5}>
        <CardMedia
            component="img"
            height="194"
            image={item.image}
            alt="Product image"
        />
        <CardContent>
            <Typography variant="h6" color="text.secondary">
            {item.name}
            </Typography>
            <Typography variant="subtitle" color="text.secondary">
            Ksh. {item.price}
            </Typography>
            <Typography color="text.secondary">
            {item.description}
            </Typography>
            <Typography color="text.secondary">
            Quantity : {item.quantity}
            </Typography>
            <Typography color="text.secondary">
            Total Amount : {item.amount}
            </Typography>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={2} mt={2}>
                
                <Button variant='contained' onClick={addToCart}>Remove from Cart</Button>
            </Box>
        </CardContent>
        <CardActions sx={{display:'flex', justifyContent:'center'}}>
            <Tooltip title='wish'>
            <IconButton aria-label="add to favorites">
            <FavoriteIcon />
            </IconButton>
            </Tooltip>
            <Tooltip title='share'>
            <IconButton aria-label="share">
            <ShareIcon />
            </IconButton>
            </Tooltip>
            <Tooltip title='edit'>
            <IconButton aria-label="edit">
            <EditIcon />
            </IconButton>
            </Tooltip>
        </CardActions>
    </Card>
    )
}

export default Item

