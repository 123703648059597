import { Chip, Divider, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import Receipts from './Components/Receipts'
import Invoice from "./Components/Invoice";
import Credit from "./Components/Credit";
import Delivery from "./Components/Delivery";
import Debit from './Components/Debit'
import Packing from "./Components/Packing";
import Catalogue from './Components/Catalogue'
import Pricelist from './Components/Pricelist'
import Consignment from './Components/Consignment'
import Lpo from './Components/Lpo'
import Damaged from './Components/Damaged'
import Promisory from './Components/Promisory'
// categories
const categories = ["Receipt", "Invoice", "Credit Note", "Delivery Note"];

const prods = [
  "Invoice1",
  "Invoice2",
  "Invoice3",
  "Invoice4",
  "Invoice5",
  "Invoice6",
];

function Documents() {
  //Manage content
  const [content, setContent] = useState(null);
  const handleEvent = (index)=>{
    switch(index){
      case categories[0]: setContent(<Receipts/>); break;
      case categories[1]: setContent(<Invoice/>); break;
      case categories[2]: setContent(<Credit/>); break;
      case categories[3]: setContent(<Delivery/>); break;
      default:
    }
}



  // Categories for products
  const [bcolor, setbColor] = useState([
    "secondary",
    "primary",
    "primary",
    "primary",
    "primary",
    "primary",
  ]);
  let resetColor = [
    "primary",
    "primary",
    "primary",
    "primary",
    "primary",
    "primary",
  ];
  const handleClickColor = (n) => {
    let newArray = [...resetColor];
    newArray[n] = "secondary";
    setbColor(newArray);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
    switch(e.currentTarget.id){
      case '0':setContent(<Packing/>); break;
      case '1':setContent(<Debit/>); break;
      case '2':setContent(<Damaged/>); break;
      case '3':setContent(<Promisory/>); break;
      case '4':setContent(<Consignment/>); break;
      case '5':setContent(<Lpo/>); break;
      case '6':setContent(<Catalogue/>); break;
      case '7':setContent(<Pricelist/>); break;
      default:setContent(<Typography>Invalid choice</Typography>)
    }
  };

  

  
  return (
    <div>
      <Stack direction={"column"} mb={2}>
        <Stack direction={"row"} mb={2}>
          {categories.map((cat, index) => {
            return (
              <Chip
                sx={{ marginRight: 2 }}
                label={cat}
                color={bcolor[index]}
                onClick={() => {handleClickColor(index); handleEvent(cat)}}
              />
            );
          })}
          <Chip
            label="Others"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            icon={<ArrowDropDownOutlinedIcon />}
          />

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem id="0" onClick={(e)=>handleClose(e)}>Packing Note</MenuItem>
            <MenuItem id="1" onClick={handleClose}>Debit Note</MenuItem>
            <MenuItem id="2" onClick={handleClose}>Damaged Goods Note</MenuItem>
            <MenuItem id="3" onClick={handleClose}>Promisory Note</MenuItem>
            <MenuItem id="4" onClick={handleClose}>Consignment</MenuItem>
            <MenuItem id="5" onClick={handleClose}>LPO</MenuItem>
            <MenuItem id="6" onClick={handleClose}>Catalogue</MenuItem>
            <MenuItem id="7" onClick={handleClose}>Pricelist</MenuItem>
          </Menu>
          <Chip
            sx={{ marginLeft: 2 }}
            label={"New"}
            color={"success"}
            // onClick={() => handleClick(index)}
          />
        </Stack>
        <Divider variant="" orientation="horizontal" />
      </Stack>
      <Stack display={"flex"} mt={1}>
        {content}
      </Stack>
    </div>
  );
}

export default Documents;
