import { Chip, Divider, Stack, } from '@mui/material';
import React, { useState } from 'react'
import Shops from './Components/Shops';




const categories = ["List","Sales"];


function Warehouses() {

  const [salestabs, setsalestabs] = useState(<Shops/>)
    const [bcolor, setbColor] = useState([
                "secondary",
                "primary",
                
              ]);
              let resetColor = [
                "primary",
                "primary",
                
              ];
              const handleClick = (n,cat) => {
                let newArray = [...resetColor];
                newArray[n] = "secondary";
                setbColor(newArray);
                switch(cat){
                  case categories[0]:setsalestabs(<Shops/>); break;
                  case categories[1]:setsalestabs("Tuesday"); break;
                  
                  default:

                }
                
              };  
          return (
            <div>
              {/* remove/ add/ edit user perms */}
              <Stack direction={"column"} mb={2}>
                <Stack direction={"row"}>
                  {categories.map((cat, index) => {
                    return (
                      <Chip
                        sx={{ marginRight: 2 }}
                        label={cat}
                        color={bcolor[index]}
                        onClick={() => handleClick(index,cat)}
                      />
                    );
                  })}
                  
                </Stack>
              </Stack>
              <Divider variant="middle" orientation="horizontal" />
              {salestabs}
                </div>
            )
        }

export default Warehouses