import { Box, Chip, Stack, Tab, } from '@mui/material'
import React, { useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Products from './Products';
import Services from './Services';
import Documents from './Documents';
import Order from './Order';
import Delivery from './Delivery';
import Allsales from './Allsales'

function Sales(props) {
    const [value, setValue] = useState("1")
    const handleChange = (event, newValue)=>{
            setValue(newValue)
    }
  return (
    <>
      <Stack>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={<Chip label='Transactions' variant="outlined"/>} value="0" />
              <Tab label={<Chip label='Products' variant="outlined"/>} value="1" />
              <Tab label={<Chip label='Services' variant="outlined"/>} value="2" />
              <Tab label={<Chip label='Business Docs' variant="outlined"/>} value="3" />
              <Tab label={<Chip label='Orders' variant="outlined"/>} value="4" />
              <Tab label={<Chip label='Delivery' variant="outlined"/>} value="5" />
            </TabList>
          </Box>
          <TabPanel value="0"><Allsales/></TabPanel>
          <TabPanel value="1"><Products/></TabPanel>
          <TabPanel value="2"><Services/></TabPanel>
          <TabPanel value="3"><Documents/></TabPanel>
          <TabPanel value="4"><Order/></TabPanel>
          <TabPanel value="5"><Delivery/></TabPanel>
        </TabContext>
      </Stack>
    </>
  );
}

export default Sales
