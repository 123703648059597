import React, { useState } from 'react'
import Create from './Create'
import { Box, Typography } from '@mui/material'

function Wares() {
    return (
        <div>
            <Create/>
        </div>
    )
}

export default Wares
export {All}

function All(props) {
  const [wareh_list, setWareh_list] = useState(
  <Box mt={3}>
    <Typography>No Warehouses Available</Typography>
  </Box>)
    return (
        <div>
            {props.wares ? setWareh_list(props.wares): null}
   
           {wareh_list}
        </div>
    )
}

