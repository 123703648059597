import React, { useState } from 'react'
import { Box, Chip, Stack, Tab, } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import SalesInv from './SalesInv';
import ProductionInv from './ProductionInv';
import Warehouses from './Warehouse';
import Stores from './Outlets';



function Inventory() {
    const [value, setValue] = useState("1")
    const handleChange = (event, newValue)=>{
            setValue(newValue)
    }
  return (
    <>
      <Stack>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={<Chip label='Production' variant="outlined"/>} value="1" />
              <Tab label={<Chip label='Sales' variant="outlined"/>} value="2" />
              <Tab label={<Chip label='Overview' variant="outlined"/>} value="3" />
              <Tab label={<Chip label='Warehouse' variant="outlined"/>} value="4" />
              <Tab label={<Chip label='Outlets' variant="outlined"/>} value="5" />
           
            </TabList>
          </Box>
          <TabPanel value="1"><ProductionInv/></TabPanel>
          <TabPanel value="2"><SalesInv/></TabPanel>
          <TabPanel value="3">Overview</TabPanel>
          <TabPanel value="4"><Warehouses/></TabPanel>
          <TabPanel value="5"><Stores/></TabPanel>
        </TabContext>
      </Stack>
    </>
  );
}

   

export default Inventory
