import { Box, Chip, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'



function ManageUsers() {
    const [bcolor, setbColor] = useState(['secondary','primary','primary','primary','primary','primary'])
    let resetColor = ['primary','primary','primary','primary','primary','primary']
    const handleClick = (n)=>{
        let newArray = [...resetColor];
        newArray[n] = "secondary";
        setbColor(newArray);
    
        // bcolor[n] = 'primary';
    }
    return (
        <div>
            {/* remove/ add/ edit user perms */}
            <Stack direction={'column'}>
                <Stack direction={'row'} spacing={2}>
                    <Chip label='Admins' color={bcolor[0]} onClick={()=>handleClick(0)}/>
                    <Chip label='Managers'color={bcolor[1]} onClick={()=>{handleClick(1)}}/>
                    <Chip label='Accountants'color={bcolor[2]} onClick={()=>{handleClick(2)}}/>
                    <Chip label='Secretaries'color={bcolor[3]} onClick={()=>{handleClick(3)}}/>
                    <Chip label='Vendors'color={bcolor[4]} onClick={()=>{handleClick(4)}}/>
                    <Chip label='Customers'color={bcolor[5]} onClick={()=>{handleClick(5)}}/>
                </Stack>
                <Box>
                    <Typography variant='h4'>list of users</Typography>
                </Box>

            </Stack>
            
        </div>
    )
}

export default ManageUsers
