import {
  Chip,
  Fab,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import "../Components/bg-image.jpg";
import EditIcon from "@mui/icons-material/Edit";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Runpayroll from "./Components/Runpayroll";

const categories = ["Run Payroll", "Deductions", "Payslip", "Doc Vault", 'Print Reports'];

function Payroll() {
  const [bcolor, setbColor] = useState([
    "secondary",
    "primary",
    "primary",
    "primary",
    "primary",
  ]);
  let resetColor = [
    "primary",
    "primary",
    "primary",
    "primary",
    "primary",
  ];
  const [component, setComponent] = useState(<Runpayroll/>)
  const handleClick = (n) => {
    let newArray = [...resetColor];
    newArray[n] = "secondary";
    setbColor(newArray);
  };
  return (
    <div>
      {/* remove/ add/ edit user perms */}
      <Stack direction={"column"} mb={2} ml={-2}>
        <Stack direction={"row"}>
          {categories.map((cat, index) => {
            return (
              <Chip
                sx={{ marginRight: 2 }}
                label={cat}
                color={bcolor[index]}
                onClick={() => handleClick(index)}
              />
            );
          })}
          <Tooltip title="settings"><SettingsOutlinedIcon color="primary"/></Tooltip>
          <Tooltip title="process salaries">
            <Chip
              color="warning"
              sx={{ marginLeft: "auto" }}
              aria-label="edit"
              label="Process"
              onClick={()=>{}}
            />
          </Tooltip>
        </Stack>
        {component}
      </Stack>
    </div>
  );
}

export default Payroll;
