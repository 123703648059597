import {Chip, Stack } from '@mui/material'
import React, { useState } from 'react'
import '../Components/bg-image.jpg'




const categories = ["Create Group", "Overview", "Asign Group", "Performance"];


function Group() {

    const [bcolor, setbColor] = useState([
        "secondary",
        "primary",
        "primary",
        "primary",
        "primary",
        "primary",
      ]);
      let resetColor = [
        "primary",
        "primary",
        "primary",
        "primary",
        "primary",
        "primary",
      ];
      const handleClick = (n) => {
        let newArray = [...resetColor];
        newArray[n] = "secondary";
        setbColor(newArray);
      };
  return (
    <div>
      {/* remove/ add/ edit user perms */}
      <Stack direction={"column"} mb={2}>
        <Stack direction={"row"}>
          {categories.map((cat, index) => {
            return (
              <Chip
                sx={{ marginRight: 2 }}
                label={cat}
                color={bcolor[index]}
                onClick={() => handleClick(index)}
              />
            );
          })}
          
        </Stack>
      </Stack>
    </div>
  );
                  
    
}

export default Group;
