import { Typography } from '@mui/material'
import React from 'react'

function Wall() {
    return (
        <div>
            <Typography variant='h5'>Wall</Typography>
            
        </div>
    )
}

export default Wall
