import { Button, Stack } from '@mui/material'
import React from 'react'

function BankAccounts() {
    return (
        <Stack display={'flex'} flexDirection={'column'} spacing={2}>
            <BusinessAcount/>
            <SavingsAccount/>
            <OtherBankTemplate/>
        </Stack>
    )
}

export default BankAccounts



function BusinessAcount() {
    return (
        <div>
            Balance and graph
            <Button variant='outlined' size='small'>Manage</Button>
        </div>
    )
}

function SavingsAccount() {
    return (
        <div>
            Balance and graph
            <Button variant='outlined' size='small'>Manage</Button>
        </div>
    )
}
function OtherBankTemplate() {
    return (
        <div>
            <Button variant='outlined' size='small'>Manage</Button>
        </div>
    )
}

