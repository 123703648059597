import { Button, Checkbox, Divider, FormControlLabel, Stack, styled, TextField, Typography } from "@mui/material";
import React from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const Fields = ['Full Name','Contact','Email','Phone','Id Number','Location',
  'Age'
]
const Business = ['Full Name','Contact','Tax Pin','Email','Phone', 'Business Name', 'Location',
  'Business Contact','Business Email'
]

function RegForm() {

  return (
    <div>
      <Stack display={"flex"} flexDirection={'row'} gap={1} sx={{width:'60em'}}>
        <Stack display={"flex"} spacing={1} ml={-2} sx={{width:'30em'}}>
        <Typography  variant="h6">Personal Contact</Typography>
          {Fields.map((field,index)=>{
            return(
                <TextField key={index} 
                id={field} 
                variant="outlined" 
                label={field}
                required
                 />
            )
          })}
          <FormControlLabel id="vendor" value={"vendor"} control={<Checkbox/>} label='Vendor'></FormControlLabel>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            gap={5}
            justifyContent={"center"}
          >
            <Button variant="outlined" size="small">
              Register
            </Button>
          </Stack>
        </Stack>
        <Divider variant="middle"  orientation="vertical"/>
        <Stack display={"flex"} flexDirection={'column'} spacing={1} sx={{width:'30em'}}>
          <Typography  variant="h6">Business Contact</Typography>
          {Business.map((bs, key)=>{
                return(
                  <TextField key={key} required id={bs} variant="outlined" label={bs}
                 />
                )
              })}
          <FormControlLabel id="vendor" value={"vendor"} control={<Checkbox/>} label='Vendor'></FormControlLabel>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            gap={5}
            justifyContent={"center"}
          >

              
            <Button variant="outlined" size="small">
              Register
            </Button>
          </Stack>
          
          
        </Stack>
      </Stack>
    </div>
  );
    
}

export default RegForm;
