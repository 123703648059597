import React from "react";
import { Chip, Divider, Menu, MenuItem, Stack } from "@mui/material";
import { useState } from "react";
import Processes from "./Components/Processes";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const categories = [
  "Processing",
  "Vendors",
  "Warehousing",
];

function ProductionInv() {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [component, setcomponent] = useState(<Processes />);

  const [bcolor, setbColor] = useState([
    "secondary",
    "primary",
    "primary",
    
  ]);
  let resetColor = [
    "primary",
    "primary",
    "primary",
    
  ];
  const handleClick = (n, cat) => {
    let newArray = [...resetColor];
    newArray[n] = "secondary";
    setbColor(newArray);
    if (cat === categories[0]) {
      setcomponent(<Processes />);
    }else if (cat === categories[1]) {
      setcomponent('Vendors');
    
    } else if (cat === categories[2]) {
      setcomponent('Warehousing');
    }
  };
  return (
    <div>
      {/* remove/ add/ edit user perms */}
      <Stack direction={"column"} mb={2}>
        <Stack direction={"row"}>
          {categories.map((cat, index) => {
            return (
              <Chip
                key={index}
                sx={{ marginRight: 2 }}
                label={cat}
                color={bcolor[index]}
                onClick={(ev) => {handleClick(index, cat);}}
                
                
              />
            );
          })}
          <Chip
                sx={{ marginLeft: 'auto' }}
                label={'Procurement'}
                color={'primary'}
                deleteIcon={<ArrowDropDownIcon />}
                onDelete={() => {}}
                onClick={(ev) => {handleClickOpen(ev)}}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                
              />
          <Menu
          transitionDuration={1000}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>Vendor Info</MenuItem>
            <MenuItem onClick={handleClose}>Create Purchase Order</MenuItem>
            <MenuItem onClick={handleClose}>Receive Order</MenuItem>
            <MenuItem onClick={handleClose}>Labeling/Tagging</MenuItem>
            <MenuItem onClick={handleClose}>Warehousing</MenuItem>
          </Menu>
        </Stack>
      </Stack>
      <Divider variant="middle" orientation="horizontal" />
      {component}
    </div>
  );
}

export default ProductionInv;
