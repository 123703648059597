import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import PermanentDrawerLeft from './Components/Drawer';
import Login from './Pages/Login';
import Createaccount from './Pages/Createaccount';
import {BrowserRouter, Route, Routes } from 'react-router-dom'

function App() {

  

  return (<>
  {/* <Createaccount/> */}
  {/* <PermanentDrawerLeft/> */}
  <BrowserRouter>
    <Routes>
      <Route path='/home' element={<Login/>}/>
      <Route path='/signup' element={<Createaccount/>}/>
      <Route path='/dashboard' element={<PermanentDrawerLeft/>}/>
    </Routes>
  </BrowserRouter>
  </>
    )
  }
export default App;
