import { Autocomplete, Box, Button, Divider, TextField, Typography } from '@mui/material'
import WarehouseBtn from './WarehouseBtn'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';




    

  //Table columns
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'product', headerName: 'Product Name', width: 130 },
        
        {
          field: 'quantity',
          headerName: 'Quantity',
          type: 'Number',
          width: 90,
        }
      ];




const warehouses = ['Nairobi Main', 'Rumuruti', 'Kisii A', 'Kisii B']
//data to be searched
const data = ['Tea', 'Lemon Tea', 'Milk 500ml','Bread 400g', 'Kiwi 80g']


function Transfer() {
    //Selected
let [products,setProducts] = useState([]);
const [num,  setNum] = useState()
const [val,  setVal] = useState()

const handleNumber = (num)=>{
    setNum(num);
}

const populateVals = (v)=>{
     setProducts({id:1, product:val, quantity:num}) 
     console.log({id:1, product:val, quantity:num});
}

const handleAdd = ()=>{
    setProducts({id:2, product:{}, quantity:{num} });
    console.log(products);
}
    return (
        
        <div>
            <Box>
                <Typography variant='h6' sx={{marginLeft:5}} >
                    Stock Tranfer
                </Typography>
                <Divider orientation='horizontal' variant='middle'/>
            </Box>
            <Box display={'column'}>
                <Typography variant='subtitle' color='primary' sx={{marginLeft:5}} >
                    Warehouse Selection
                </Typography>
                <Box flexDirection={'row'}
                    display="flex"
                    alignItems="center"
                    justifyContent={'center'}
                    gap={8}
                    p={2}
                    width={500}
                >
                    <WarehouseBtn warehouses ={warehouses} target={'Source'} />
                    <ArrowForwardOutlinedIcon fontSize='medium' color='primary'/>
                    <WarehouseBtn warehouses ={warehouses} target={'Destination'} />

                </Box>
                <Box display={'flex'}
                        flexDirection={'row'}
                        gap={2}
                        >
                    <Autocomplete
                        disablePortal
                        id="search-auto"
                        options={data}
                        onChange={(e, val) =>{setVal(val); populateVals(val);

                        }}
                        value={val}
                        sx={{ width: 250 }}
                        renderInput={(params) => <TextField {...params} label="Search..." />} />
                    <TextField
                        id="outlined-number"
                        label="Quantity"
                        type="number"
                        value={num}
                        onChange={(e, num)=>handleNumber(num)}
                        InputLabelProps={{
                            shrink: true,
                        }}/>
                    <Button variant='contained' color='primary' onClick={handleAdd}>Add</Button>
                </Box>
            </Box>
            <Box display={'flex'} mt={3}>
            <DataGrid
                rows={products}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
      />
            </Box>         
            <Box sx={{display:'flex', justifyContent:'center', margin:4}}><Button variant='outlined' sx={{width:0.5}}>Transfer</Button></Box>
          
            
        </div>
    )
}

export default Transfer
