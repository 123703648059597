import React from 'react'
import Box from '@mui/material/Box';
import { Button, Divider, TextField, Typography } from '@mui/material';


function Create() {
    return (
        <div>
            <Box>
                <Typography variant='h6' sx={{marginLeft:5}} >
                    New Warehouse
                </Typography>
                <Divider orientation='horizontal' variant='middle'/>
            </Box>
            <form noValidate>
            <Box display={'column'} rowGap={3}>
            <TextField id="standard-basic" label="Name" variant="standard" fullWidth />
            <TextField id="standard-basic" label="Location" variant="standard" fullWidth/>
            <TextField id="standard-basic" label="Contact" variant="standard" fullWidth />
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', margin:4}}><Button variant='outlined' sx={{width:0.5}}>Save</Button></Box>
            </form>
        </div>
    )
}

export default Create
