import { Box, Chip, Grid, Stack, Tab, Typography } from '@mui/material'
import React, { useState } from 'react'
import Employee from './Employee'
import Job from './Job'
import Payroll from './Payroll'
import Leave from './Leave'
import Group from './Group'
import Work from './Work'
import { TabContext, TabList, TabPanel } from '@mui/lab'

function MainHRM() {
    const [value, setValue] = useState("1")
    const handleChange = (event, newValue)=>{
            setValue(newValue)
    }
  return (
    <>
      <Stack>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={<Chip label='Employee' variant="outlined"/>} value="1" />
              <Tab label={<Chip label='Payroll' variant="outlined"/>} value="2" />
              <Tab label={<Chip label='Work' variant="outlined"/>} value="3" />
              <Tab label={<Chip label='Groups' variant="outlined"/>} value="4" />
              <Tab label={<Chip label='Leave' variant="outlined"/>} value="5" />
            </TabList>
          </Box>
          <TabPanel value="1"><Employee/></TabPanel>
          <TabPanel value="2"><Payroll/></TabPanel>
          <TabPanel value="3"><Work/></TabPanel>
          <TabPanel value="4"><Group/></TabPanel>
          <TabPanel value="5"><Leave/></TabPanel>
        </TabContext>
      </Stack>
    </>
  );
}

export default MainHRM
