import { Typography } from '@mui/material'
import React from 'react'

function Charts() {
    return (
        <div>
            {/* general overview of charts */}
            <Typography variant='h4'>Display charts</Typography>
        </div>
    )
}

export default Charts
