import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Chip, Stack, Tab } from "@mui/material";
import { useState } from "react";
import RegForm from "./RegForm";
import Dashboard from "./Dashboard";
import Deals from "./Deals";
import Leads from "./Leads";
import Activities from "./Activities";
import CustomersReports from "./CustomersReports";
import AllContacts from "./Components/AllContacts";

function Customer() {
    const [value, setValue] = useState("1")
    const handleChange = (event, newValue)=>{
            setValue(newValue)
        }
  return (
    <>
      <Stack>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="tabs example">
              <Tab label={<Chip label='Dashboard' variant="outlined"/>} value="0" />
              <Tab label={<Chip label='Contacts' variant="outlined"/>} value="1" />
              <Tab label={<Chip label='Prospects' variant="outlined"/>} value="2" />
              <Tab label={<Chip label='Create Contact' variant="outlined"/>} value="3" />
              <Tab label={<Chip label='Deals' variant="outlined"/>} value="4" />
              <Tab label={<Chip label='Activities' variant="outlined"/>} value="5" />
              <Tab label={<Chip label='Reports' variant="outlined"/>} value="6" />
            </TabList>
          </Box>
          <TabPanel value="0"><Dashboard/></TabPanel>
          <TabPanel value="1"><AllContacts/></TabPanel>
          <TabPanel value="2"><Leads/></TabPanel>
          <TabPanel value="3"><RegForm/></TabPanel>
          <TabPanel value="4"><Deals/></TabPanel>
          <TabPanel value="5"><Activities/></TabPanel>
          <TabPanel value="6"><CustomersReports/></TabPanel>
        </TabContext>
      </Stack>
    </>
  );
}

export default Customer
