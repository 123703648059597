import { Task } from "@mui/icons-material";
import { TabList } from "@mui/lab";
import { Button, Chip, Stack, Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import CreateTask from "./CreateTask";

function Todo() {
  const [bcolor, setbColor] = useState([
    "secondary",
    "primary",
    "primary",
    "primary",
    "primary",
    "primary",
  ]);
  let resetColor = [
    "primary",
    "primary",
    "primary",
    "primary",
    "primary",
    "primary",
  ];
  const handleClick = (n) => {
    let newArray = [...resetColor];
    newArray[n] = "secondary";
    setbColor(newArray);
  }

    return (
      <Stack spacing={3}>
        <Stack direction={"row"} spacing={2}>
          <Chip
            label="New"
            color={bcolor[0]}
            onClick={() => handleClick(0)}
          />
          <Chip
            label="Pending Tasks"
            color={bcolor[1]}
            onClick={() => {
              handleClick(1);
            }}
          />
          <Chip
            label="Completed"
            color={bcolor[2]}
            onClick={() => {
              handleClick(2);
            }}
          />
          
        </Stack>
        <Stack>
            <CreateTask/>
        </Stack>
      </Stack>
    );
  
}

export default Todo;
