import React from 'react'
import img from '../img/bg-image.jpg'
import { Badge, Box, Button, Card, CardContent, CardHeader, CardMedia, Skeleton, Stack, TextField, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import { Info } from '@mui/icons-material';

function AccountSettings() {
    return (
        <div>
            {/* change username, password, profile picture, phone, email */}
            <Stack direction={'column'}>
                <CardHeader
                title = {<Typography variant='h4'>Profile</Typography>}
                />
                <Badge badgeContent={<EditIcon/>}><CardMedia
                    component="img"
                    height="194"
                    image={img}
                    sx={{mb:2}}
                /></Badge>
                <Stack direction={'column'} spacing={2}>
                    <TextField variant='outlined' label='Username' required></TextField>
                    <TextField variant='outlined' label='First Name' required></TextField>
                    <TextField variant='outlined' label='Last Name' required></TextField>
                    <TextField variant='outlined' label='Email' required></TextField>
                    <TextField variant='outlined' label='Phone' required></TextField>
                    <Button variant='contained'>Save</Button>
                    <Typography variant='subtitle' color='primary'>Change Password?</Typography>
                </Stack>
                

                
            </Stack>
            
        </div>
    )
}

export default AccountSettings
