import React from 'react'
import { Card, Typography } from '@mui/material'

function Manufacture() {
    return (
        <>
        <Card elevation={0}  sx={{ display: 'flex', marginTop:1, flexDirection:'column',p:3, gap:5, marginBottom:5, justifyContent:'left' }}>
                <Typography variant="h6" color="primary">
                Manufacture
                </Typography>
        </Card>
        </>
    )   
}

export default Manufacture
