import React from 'react'

function Debit() {
    return (
        <div>
            This is Debit
        </div>
    )
}

export default Debit
