import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Chip, Stack, Tab, Typography } from '@mui/material'
import React, { useState } from 'react'
import Bids from './Components/Bids';
import Contracts from './Components/Contacts';
import Expenses from './Billandexpense';

const Tabs = ["Expenses & Bills","List","Bids/Tenders","Contracts","Docs"];


function Vendors() {
    
        const [value, setValue] = useState("1")
        const handleChange = (event, newValue)=>{
                setValue(newValue)
        }
    return (
        <>
      <Stack>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
                {Tabs.map((tab, index)=>{
                    return(
                        <Tab label={<Chip label={tab} variant="outlined"/>} value={index} />
                    )
                })}
              
            </TabList>
          </Box>
          
          <TabPanel value={0}><Expenses/></TabPanel>
          <TabPanel value={1}>List</TabPanel>
          <TabPanel value={2}><Bids/></TabPanel>
          <TabPanel value={3}><Contracts/></TabPanel>
          <TabPanel value={4}>Docs</TabPanel>
          
        </TabContext>
      </Stack>
    </>
    )
}

export default Vendors
