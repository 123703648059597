import { Box, Chip, Stack, Tab } from "@mui/material";
import React, { useState } from "react";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Company from "./Company";
import AccountSettings from "./AccountSettings";
import ManageUsers from "./ManageUsers";
import Charts from "./Charts";

function Setting() {
    const [value, setValue] = useState("1")
    const handleChange = (event, newValue)=>{
            setValue(newValue)
    }
  return (
    <>
      <Stack>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={<Chip label='Company' variant="outlined"/>} value="1" />
              <Tab label={<Chip label='Account' variant="outlined"/>} value="2" />
              <Tab label={<Chip label='Users' variant="outlined"/>} value="3" />
              <Tab label={<Chip label='Charts' variant="outlined"/>} value="4" />
              <Tab label={<Chip label='Tools' variant="outlined"/>} value="5" />
            </TabList>
          </Box>
          <TabPanel value="1"><Company/></TabPanel>
          <TabPanel value="2"><AccountSettings/></TabPanel>
          <TabPanel value="3"><ManageUsers/></TabPanel>
          <TabPanel value="4"><Charts/></TabPanel>
          <TabPanel value="5">Tools Order cheques Import data reconcile accounts screen share</TabPanel>
        </TabContext>
      </Stack>
    </>
  );
}

export default Setting;
