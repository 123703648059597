import { Button, Card,Stack, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, TextField, Typography, FormControl, InputLabel, Select, MenuItem, Link } from '@mui/material'
import React, { useState } from 'react'
import {useForm} from 'react-hook-form'

function Createaccount() {
        const category = ['Employee','Customer','Vendor','Admin'];
        const sex = ['Male','Female'];

        const [gender, setGender] = useState("Male")
        const [categ, setCateg] = useState(category[0])


        const myform = useForm()
        const {register, handleSubmit} = myform



        const handleChange = (e)=>{
            setGender(e.target.value)
        }
        const handleCategory = (e)=>{
            setCateg(e.target.value)
           
        }
    return (
        <div className='signupCard'>
            <Card elevation={5}>
                <CardHeader
                title =  {<Typography variant='h4' align='center' color='primary'>Create Account</Typography>}
                subheader = {<Typography variant='h6' align='center' color='error'>All fields with * are necessary</Typography>}
                >   
                </CardHeader>
                <CardContent>
                    <form noValidate>
                        <Stack spacing={2} direction='row' mb={3}>
                        <TextField
                            id="first_name"
                            label="First Name"
                            fullWidth = {false}
                            required
                            size='small'
                            {...register('first_name')}
                            />
                            <TextField
                            id="last_name"
                            label="Last Name"
                            fullWidth = {false}
                            required
                            size='small'
                            {...register('last name')}
                            />
                            <TextField
                            id="email"
                            label="Email"
                            fullWidth = {false}
                            required
                            size='small'
                            />
                        </Stack>
                        <Divider orientation='horizontal'/>
                        <Stack spacing={2} direction='row' mt={3} mb={3}>
                            <FormControl sx={{width:1/4}}>
                            <InputLabel id="input_gender">Gender</InputLabel>
                            <Select
                                labelId="gender"
                                id="gender"
                                size='small'
                                value={gender}
                                label="Gender"
                                onChange={handleChange}
                            >
                                <MenuItem value={'Male'}>Male</MenuItem>
                                <MenuItem value={'Female'}>Female</MenuItem>
                                
                            </Select>
                            </FormControl>
                            <TextField
                            size='small'
                            id="contact"
                            label="Contact"
                            fullWidth = {false}
                            required
                            />
                            <TextField
                            size='small'
                            id="id_number"
                            label="ID"
                            fullWidth = {false}
                            required
                            />
                        </Stack>
                        <Divider orientation='horizontal'/>
                        <Stack spacing={2} direction='row' mt={3} mb={3}>
                        <TextField
                            id="city"
                            size='small'
                            label="City"
                            fullWidth = {false}
                            required
                            />
                            <FormControl sx={{width:1/4}}>
                            <InputLabel id="input_category">Category</InputLabel>
                            <Select
                                labelId="Category"
                                id="category"
                                size='small'
                                value={categ}
                                label="Category"
                                onChange={handleCategory}
                            >
                                <MenuItem value={category[0]}>{category[0]}</MenuItem>
                                <MenuItem value={category[1]}>{category[1]}</MenuItem>
                                <MenuItem value={category[2]}>{category[2]}</MenuItem>
                                <MenuItem value={category[3]}>{category[3]}</MenuItem>
                            </Select>
                            </FormControl>
                            <TextField
                            id="id_number"
                            size='small'
                            label="ID Number"
                            fullWidth = {false}
                            required
                            />
                        </Stack> 
                        <Divider orientation='horizontal'/>
                        <Stack spacing={2} direction='row' mb={3} mt={3}>
                        <TextField
                            id="password"
                            label="Password"
                            type="password"
                            size='small'
                            fullWidth
                            required
                            />
                            <TextField
                            id="password2"
                            label="Confirm Password"
                            type="password"
                            size='small'
                            fullWidth
                            required
                            />
                            
                        </Stack>
                        <Stack spacing={7} direction='row' mt={3} mb={3} className='Signup-btn'>
                            <Button variant='contained'  sx={{width:1/5}}>Sign Up</Button> 
                        </Stack>   
                    </form>
                    <div className='forgotPassword'>
                        <Button variant='outlined' size='small' href='/home'>Log in</Button>
                        <Divider orientation="vertical" flexItem />
                        <Button size='small' onClick={()=>window.open("https://web.facebook.com/watch/?ref=tab&_rdc=1&_rdr")} >Use Social Media?</Button>
                    </div>
                </CardContent>

                

            </Card>
        </div>
    )
}

export default Createaccount
