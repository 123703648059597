import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import React from "react";

const Fields = ['Full Name','Email','Telephone',
    'Location','Business Name','Product','Pakage']


function Leads() {
  return (
    <div>
      <Stack display={"flex"} flexDirection={'row'} gap={2}>
        <Stack display={"flex"} spacing={1} sx={{width:350}}>
        <Typography  variant="h6">Create Lead</Typography>
          {Fields.map((field,index)=>{
            return(
                <TextField key={index} id={field} variant="outlined" label={field}
                 />
            )
          })}
          <Stack
            display={"flex"}
            flexDirection={"row"}
            gap={5}
            justifyContent={"center"}
          >
            <Button variant="outlined" size="small">
              Task
            </Button>
            <Button variant="outlined" size="small">
              Meet
            </Button>
            <Button variant="outlined" size="small">
              Contact
            </Button>
          </Stack>
        </Stack>
        <Divider  orientation="vertical"/>
        <Stack display={"flex"} spacing={1} sx={{width:350}}>
        <Typography  variant="h6">Leads</Typography>
        <Typography  variant="text">Full Name</Typography>
        <Typography  variant="text">Contact</Typography>
        <Typography  variant="text">Location</Typography>
        <Stack
            display={"flex"}
            flexDirection={"row"}
            gap={5}
            justifyContent={"center"}
          >
            <Button variant="outlined" size="small">
              Register
            </Button>
            <Button variant="outlined" size="small">
              Email
            </Button>
            <Button variant="outlined" size="small">
              Delete
            </Button>
          </Stack>
          <Button variant="outlined" size="small" fullWidth>
              More Details
            </Button>
          
        </Stack>
      </Stack>
    </div>
  );
}

export default Leads;
