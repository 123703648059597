import React from 'react'
import { Button, Card, Grid, Typography } from '@mui/material'
import CartItem from './CartItem'

function Cart(props) {
    // const cart = props.cart;
    const cart = [{name:'Bread', price:250, description:'Whole Meal Bread',quantity:12, amount:3000},
        {name:'Sugar', price:200, description:'Nzoia Sugar 1Kg',quantity:2, amount:400},
        {name:'Tea', price:210, description:'Ketepa Kenya 250g',quantity:3, amount:630},
        {name:'Green Tea', price:250, description:'Kapretwa 120g',quantity:15, amount:3750},
        {name:'Salt', price:100, description:'Sea salt 1Kg',quantity:1, amount:100},
        {name:'Cheese', price:750, description:'French Cheese 250g',quantity:10, amount:7500},
        {name:'Cookies', price:360, description:'Manji Crunchy 250g',quantity:11, amount:3960},
        {name:'Kettle', price:1200, description:'Philips 2.5L',quantity:1, amount:1200},
        {name:'Sandals', price:1550, description:'Bata Best Quality sz-6',quantity:50, amount:77500},
    ]

    const handleCheckOut = ()=>{
        ///checkout code here
    }


  

    return (
        <div>
            <Card elevation={5} sx={{ display: 'flex', flexDirection:'row',p:3, gap:5, marginBottom:5, justifyContent:'right' }}>
            <Typography variant="h6" color="primary">
            Total Amount : {0}
            </Typography>
            <Button variant='contained' onClick={handleCheckOut}>Check Out</Button>
            </Card>
            <Grid container  spacing={2}>
                {cart.map((product)=>{

                    return(
                    <Grid item xs={4}>
                        <CartItem item={product}/>
                    </Grid>
                    )
                      })}
                    
                
            </Grid>
        </div>
    )
}

export default Cart
