import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import imge from "../img/bg-image.jpg";

function Company() {
  //typography size
  const [size, setsize] = useState("small");
  // handle defaults
  const [Invoice, setInvoice] = useState(false);
  const [employee, setemployee] = useState(false);
  const [customer, setcustomer] = useState(false);
  const [receipt, setreceipt] = useState(false);
  const [disableInvoice, setdisableInvoice] = useState(false);
  const [disableEmployee, setdisableEmployee] = useState(false);
  const [disableCustomer, setdisableCustomer] = useState(false);
  const [disableReceipt, setdisableReceipt] = useState(false);

  const handleInvoiceDefaults = () => {
    if (Invoice) {
      setInvoice(false);
      setdisableInvoice(false);
    } else {
      setInvoice(true);
      setdisableInvoice(true);
    }
  };

  const handleEmployeeDefaults = () => {
    if (employee) {
      setemployee(false);
      setdisableEmployee(false);
    } else {
      setemployee(true);
      setdisableEmployee(true);
    }
  };

  const handleCustomerDefaults = () => {
    if (customer) {
      setcustomer(false);
      setdisableCustomer(false);
    } else {
      setcustomer(true);
      setdisableCustomer(true);
    }
  };

  const handleReceiptDefaults = () => {
    if (receipt) {
      setreceipt(false);
      setdisableReceipt(false);
    } else {
      setreceipt(true);
      setdisableReceipt(true);
    }
  };

  return (
    <div>
      {/* set company logo and name */}
      <Card elevation={3} sx={{ width: "inherit" }}>
        <CardHeader
          title={<Typography variant="h4">Company Profile</Typography>}
          subheader={<Typography variant="subtitle">Company Logo</Typography>}
        />
        <CardMedia
          component="img"
          height="194"
          width={300}
          image={imge}
          alt="Change Profile"
        />
        <CardContent>
          <Stack direction={"column"} spacing={2}>
            <TextField
              id="company-name"
              variant="outlined"
              label="Company Name"
              size={size}
              required
            ></TextField>
            <TextField
              id="slogan"
              size={size}
              variant="outlined"
              label="Slogan/Motto"
              required
            ></TextField>
            <TextField
              id="description"
              size={size}
              variant="outlined"
              label="Description"
              required
            ></TextField>
            <TextField
              id="taxnumber"
              variant="outlined"
              size={size}
              label="Tax Reg Number"
              required
            ></TextField>
            <TextField
              variant="outlined"
              size={size}
              label="Foot note"
              required
            ></TextField>
          </Stack>
          <Card elevation={2} sx={{ padding: 2, marginTop: 2 }}>
            <Stack direction={"column"} mt={2} spacing={2}>
              <Stack display={"flex"} flexDirection={"row"}>
                <Typography variant="h6">Invoice</Typography>
                <FormControlLabel
                  onClick={handleInvoiceDefaults}
                  checked={Invoice}
                  sx={{ marginLeft: "auto" }}
                  value={"inv"}
                  control={<Checkbox />}
                  label="Use System Defaults"
                ></FormControlLabel>
              </Stack>
              <Divider variant="middle" />
              <TextField
                id="invoice-start"
                variant="outlined"
                label="Invoice Prefix"
                disabled={disableInvoice}
                size={size}
                required
              ></TextField>
              <TextField
                id="origin"
                size={size}
                variant="outlined"
                label="Start at: (Ensure no collision with Existing)"
                type="number"
                disabled={disableInvoice}
                required
              ></TextField>
              <TextField
                id="max-days"
                variant="outlined"
                label="Max Days Due"
                type="number"
                size={size}
                disabled={disableInvoice}
                required
              ></TextField>
              <TextField
                id="text"
                size={size}
                variant="outlined"
                label="Thank you message"
                multiline
                disabled={disableInvoice}
                maxRows={3}
                minRows={3}
                required
              ></TextField>

            </Stack>
          </Card>
          <Card elevation={2} sx={{ padding: 2, marginTop: 2 }}>
            <Stack direction={"column"} mt={2} spacing={2}>
              <Stack display={"flex"} flexDirection={"row"}>
                <Typography variant="h6">Employee</Typography>
                <FormControlLabel
                  onClick={handleEmployeeDefaults}
                  checked={employee}
                  sx={{ marginLeft: "auto" }}
                  value={"use-inv-defaults"}
                  control={<Checkbox />}
                  label="Use System Defaults"
                ></FormControlLabel>
              </Stack>

              <TextField
                id="cust-id"
                size={size}
                variant="outlined"
                label="Customer Id Prefix"
                disabled={disableEmployee}
                required
              ></TextField>
              <TextField
                id="emp-id"
                variant="outlined"
                size={size}
                disabled={disableEmployee}
                label="Start at: (Ensure no collision with Existing)"
                type="number"
                required
              ></TextField>
              <TextField
                id="types"
                size={size}
                disabled={disableEmployee}
                variant="outlined"
                multiline
                minRows={4}
                maxRows={4}
                label="Enter Employee Types (Comma Separated) e.g 'Accounts, Salesperson,..."
                required
              ></TextField>
              <TextField
                id="max-leave-days"
                size={size}
                disabled={disableEmployee}
                variant="outlined"
                label="Max Leave Days"
                type="number"
                required
              ></TextField>
              <TextField
                id="welcome-text"
                size={size}
                disabled={disableEmployee}
                variant="outlined"
                label="Welcome Message"
                multiline
                maxRows={3}
                minRows={3}
                required
              ></TextField>

              
            </Stack>
          </Card>
          <Card elevation={2} sx={{ padding: 2, marginTop: 2 }}>
            <Stack direction={"column"} mt={2} spacing={2}>
              <Stack display={"flex"} flexDirection={"row"}>
                <Typography variant="h6">Customer</Typography>
                <FormControlLabel
                  onClick={handleCustomerDefaults}
                  checked={customer}
                  sx={{ marginLeft: "auto" }}
                  value={"use-inv-defaults"}
                  control={<Checkbox />}
                  label="Use System Defaults"
                ></FormControlLabel>
              </Stack>

              <TextField
                id="cust-ref-pref"
                size={size}
                disabled={disableCustomer}
                variant="outlined"
                label="Customer Ref Prefix"
                required
              ></TextField>
              <TextField
                id="begin"
                size={size}
                disabled={disableCustomer}
                variant="outlined"
                label="Start at: (Ensure no collision with Existing)"
                type="number"
                required
              ></TextField>

              <TextField
                id="text"
                size={size}
                disabled={disableCustomer}
                variant="outlined"
                label="Thank you message"
                multiline
                maxRows={3}
                minRows={3}
                required
              ></TextField>

              
            </Stack>
          </Card>
          <Card elevation={2} sx={{ padding: 2, marginTop: 2 }}>
            <Stack direction={"column"} mt={2} spacing={2}>
              <Stack display={"flex"} flexDirection={"row"}>
                <Typography variant="h6">Receipt</Typography>
                <FormControlLabel
                  onClick={handleReceiptDefaults}
                  checked={receipt}
                  sx={{ marginLeft: "auto" }}
                  value={"use-inv-defaults"}
                  control={<Checkbox />}
                  label="Use System Defaults"
                ></FormControlLabel>
              </Stack>

              <TextField
                id="receipt-start"
                size={size}
                disabled={disableReceipt}
                variant="outlined"
                label="Receipt Prefix"
                required
              ></TextField>
              <TextField
                id="origin"
                size={size}
                disabled={disableReceipt}
                variant="outlined"
                label="Start at: (Ensure no collision with Existing)"
                type="number"
                required
              ></TextField>
              <TextField
                id="max-amount"
                size={size}
                disabled={disableReceipt}
                variant="outlined"
                label="Max Amount Cash"
                type="number"
                required
              ></TextField>
              <TextField
                id="text"
                size={size}
                disabled={disableReceipt}
                variant="outlined"
                label="Thank you message(s) (if more, should be comma separated)"
                multiline
                maxRows={3}
                minRows={3}
                required
              ></TextField>
              <Stack display={"flex"} flexDirection={"row"} spacing={2} justifyContent={'center'}>
                <Typography variant="subtitle">Extra Receipt Info</Typography>
                <div className="containSaler">
                  <FormControlLabel
                    disabled={disableReceipt}
                    value={"saler"}
                    control={<Checkbox />}
                    label="Sales Person"
                  ></FormControlLabel>
                </div>
                <div className="tax-pin">
                  <FormControlLabel
                    disabled={disableReceipt}
                    value={"tax-pin"}
                    control={<Checkbox size={size} />}
                    label="Tax Registration Number (KRA PIN)"
                  ></FormControlLabel>
                </div>
                
              </Stack>
              
            </Stack>
          </Card>
          <Stack mt={2} display={'flex'}>
            <Button variant="contained" fullWidth size={size}>
                Save
              </Button>
          </Stack>
          
        </CardContent>
      </Card>
    </div>
  );
}

export default Company;

function Logo() {
  return <div></div>;
}

export { Logo };
