import React from 'react'
import { Box, Chip, Stack, Tab } from "@mui/material";
import { useState } from "react";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import BankAccounts from './BankAccounts';

function Accounting() {
    const [value, setValue] = useState("1")
    const handleChange = (event, newValue)=>{
            setValue(newValue)
    }
  return (
    <>
      <Stack>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={<Chip label='Company' variant="outlined"/>} value="1" />
              <Tab label={<Chip label='Outlets' variant="outlined"/>} value="2" />
              <Tab label={<Chip label='Warehouse' variant="outlined"/>} value="3" />
              <Tab label={<Chip label='Customer' variant="outlined"/>} value="4" />
              <Tab label={<Chip label='Vendor' variant="outlined"/>} value="5" />
              <Tab label={<Chip label='Production' variant="outlined"/>} value="6" />
              <Tab label={<Chip label='Bank' variant="outlined"/>} value="7" />
            </TabList>
          </Box>
          <TabPanel value="1">Company</TabPanel>
          <TabPanel value="2">Outlets</TabPanel>
          <TabPanel value="3">Warehouses</TabPanel>
          <TabPanel value="4">Customers</TabPanel>
          <TabPanel value="5">Vendor</TabPanel>
          <TabPanel value="6">Production</TabPanel>
          <TabPanel value="7"><BankAccounts/></TabPanel>
        </TabContext>
      </Stack>
    </>
  );
}
export default Accounting
