import { Box, Card, Stack, Typography } from "@mui/material";
import React from "react";


function Calendar() {
  return (
    <Card
      elevation={3}
      sx={{ padding: 3, m: 1, width: "25vw", height: "80vh" }}
    >
      <Stack mt={5}>
        <Typography variant="Calendar">Calendar</Typography>
        
      </Stack>
    </Card>
  );
}

export default Calendar;
