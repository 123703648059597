import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PeopleIcon from '@mui/icons-material/People';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
import AssuredWorkloadRoundedIcon from '@mui/icons-material/AssuredWorkloadRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import AttributionIcon from '@mui/icons-material/Attribution';
import FactoryIcon from '@mui/icons-material/Factory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import EmailIcon from '@mui/icons-material/Email';
import ResponsiveAppBar from './Appbar';
import MainHRM from '../HRM/MainHRM';
import Sales from '../Sales/Sales';
import Inventory from '../Inventory/Inventory'
import Manufacture from '../Manufacture/Manufacture'
import Vendors from '../Vendor/Vendors'
import Warehousing from '../Wareh/Warehousing'
import Accounting from '../Accounts/Accounting'
import Customer from '../Customer/Customer';
import Mail from '../Mailing/Mail'
// import Accounting from './/Accounting';



// const drawerWidth = 80;

export default function PermanentDrawerLeft() {
  const [drawerWidth, setdWidth] = useState(80)

    const[appbarTitle, setAppbartitle] = useState('HRM')
    const[component, setComponent] = useState(<MainHRM/>)
    const swapScreen = (e)=>{setComponent(e)}
    
    const mainMenu = [{title: "HRM", icon : <PeopleIcon/>},
      {title: "Vendors", icon : <AssuredWorkloadRoundedIcon/>},
      {title: "Sales", icon : <MonetizationOnOutlinedIcon/>},
      {title: "Inventory", icon : <Inventory2RoundedIcon/>},
      {title: "Customer Management", icon : <AttributionIcon/>},
      {title: "Accounting", icon : <AccountBalanceRoundedIcon/>},
      {title: "Manufacturing", icon : <FactoryIcon/>},
      {title: "Logistics", icon : <LocalShippingIcon/>},
      {title: "Warehousing", icon : <WarehouseIcon/>}
    ]
  
    const subMenu = [{title: "Business Intel", icon : <PsychologyOutlinedIcon/>},
      {title: "Reports", icon : <AssessmentOutlinedIcon/>},
      {title: "Mails", icon : <EmailIcon/>},
  
    ]


  const handleClick = (title,e) =>{
    
      setAppbartitle(title)
      if(title==='HRM'){
        setComponent(<MainHRM/>)
      }else if(title==='Sales'){
        setComponent(<Sales method={swapScreen}/>);
      }
      else if(title==='Warehousing'){
        setComponent(<Warehousing method={swapScreen}/>);
      }
      else if(title==='Vendors'){
        setComponent(<Vendors/>);
      }
      else if(title==='Inventory'){
        setComponent(<Inventory/>);
      }
      else if(title==='Customer Management'){
        setComponent(<Customer/>);
      }
      else if(title==='Accounting'){
        setComponent(<Accounting/>);
      }
      else if(title==='Manufacturing'){
        setComponent(<Manufacture/>);
      }
      else if(title==='Logistics'){
        setComponent("Logistics");
      }
      else if(title==='Mails'){
        setComponent(<Mail/>);
      }
      else if(title==='Manufacturing and Logistics'){
        setComponent(<Manufacture/>);
      }
      else if(title==='Manufacturing and Logistics'){
        setComponent(<Manufacture/>);
      }
  }
  return (

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <ResponsiveAppBar method = {setComponent} title={appbarTitle} sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}/>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
        onMouseEnter={()=>setdWidth(200)}
        onMouseLeave={()=>setdWidth(80)}
      ><Typography variant='h4'
      align='center' mt={2} color='primary'
     >KAL-Soft</Typography>
        <Typography variant='p'
       align='center' mt={2}
      >Welcome</Typography>
        <Toolbar />
        <Divider />
        <List>
          {mainMenu.map((item, index) => (
            <ListItem key={item.title} disablePadding onClick={(e) =>handleClick(item.title,e)} >
              <ListItemButton >
                <ListItemIcon >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {subMenu.map((item,index) => (
            <ListItem key={index} disablePadding onClick={()=> {handleClick(item.title)}}>
              <ListItemButton >
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
        <Box className = 'mainBody' id = 'main'>
          {/* <MainHRM/> */}
        </Box>
        {component}
        {/* { <Vendors/> } */}
        {/* <Profile/> */}
        
          
      
        
     
      </Box>
    </Box>
  );
}
